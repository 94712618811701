/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import { Root, createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import ReduxProvider from '@client/reduxProvider';
import React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import localforage from 'localforage';
import { pdfjs } from 'react-pdf';
import { Env, getEnvFromHostname } from '@drayalliance/utils';
import { initAnalytics } from './utils/analytics';
import { datadogInspector, initRum } from './utils/datadog';
import ErrorBoundary from './components/ErrorBoundary';

import 'sanitize.css/sanitize.css';
import './components/deprecatedTookit/styles/index.css';
import './translations/i18n';

// Import root app
import App from './components/App';

// Load the favicon and the .htaccess file
import 'images/favicon.ico';
// eslint-disable-next-line import/extensions
import './.htaccess';

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

// These are effectively globals
const MOUNT_NODE = document.getElementById('app');

// Create localforage disk-based cache backed by IndexedDB.
localforage.config({
  driver: localforage.INDEXEDDB,
  name: 'hub',
  version: 1.0,
  storeName: 'hub_cache',
  description: 'Cached data for Hub'
});

let root: Root;

const render = async () => {
  const { env } = getEnvFromHostname(window.location.hostname);
  let ldClientSideID;
  switch (env) {
    case Env.Stage:
      // STAGE - CLIENT ID
      ldClientSideID = '5fd17091b702a50ae41b12f4';
      break;
    case Env.Production:
      // PROD - CLIENT ID
      ldClientSideID = '5ed7e502c5b3d80a3576a8d4';
      break;
    case Env.Test:
    default:
      // TEST - CLIENT ID
      ldClientSideID = '5ed7e502c5b3d80a3576a8d3';
      break;
  }

  initRum(env);

  const LDProvider = await asyncWithLDProvider({
    // TODO: Move these keys into env variables
    clientSideID: ldClientSideID,
    options: {
      bootstrap: 'localStorage',
      inspectors: [datadogInspector()]
    }
  });

  initAnalytics();

  root = createRoot(MOUNT_NODE!);

  root.render(
    <LDProvider>
      <ReduxProvider>
        <IntlProvider locale="en">
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </IntlProvider>
      </ReduxProvider>
    </LDProvider>
  );
};

// FIXME: Webpack adds this through HMR and not sure how to type it
if ((module as any).hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  (module as any).hot.accept(['./components/App'], () => {
    root.unmount();
    render();
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    // eslint-disable-next-line import/extensions
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .catch((err) => {
      throw err;
    });
} else {
  render();
}

// Install ServiceWorker in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  const ReactGA = require('react-ga'); // eslint-disable-line
  ReactGA.initialize('UA-104401153-3', {
    debug: false
  });
}
